export const ROUTES = {
    HOME: {
        path: '/',
        name: 'Home',
    },
    CODE: {
        path: '/code',
        name: 'Code',
    },
    MUSIC: {
        path: '/music',
        name: 'Music',
    },
    ART: {
        path: '/art',
        name: 'Art',
    },
    BLOG: {
        path: '/blog',
        name: 'Blog',
    },
};

export const NAV_LINKS = [
    ROUTES.HOME,
];

export const SOCIALS = {
    INSTAGRAM: 'https://www.instagram.com/nieky._/',
    YOUTUBE: 'https://www.youtube.com/channel/UCOzXkBu2s-tVVzMugikYnBA',
    SPOTIFY: 'https://open.spotify.com/artist/5GrHxUUOqV556DKORjoeTk',
    APPLE_MUSIC: 'https://music.apple.com/tr/artist/dna/1485118749',
};
