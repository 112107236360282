import React from 'react';
import clsx from 'clsx';
import useStyles from './styles';
import BaseLink from 'atoms/BaseLink';
import Image from 'next/image';

const BrandLogo = (props: BrandLogoPropTypes) => {
    const classes = useStyles();

    return (
        <BaseLink href={'/'}>
            <Image width={'72px'} height={'48px'} src={'/static/img/na-logo.png'} />
        </BaseLink>
    );
};

export type BrandLogoPropTypes = {};

export default BrandLogo;
