import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
    typography: {
        button: {
            fontFamily: `'Rubik', sans-serif`,
            color: '#ffffff',
        },
        allVariants: {
            fontFamily: `'Rubik', sans-serif`,
            textDecoration: 'none',
        },
    },
    overrides: {
        MuiLink: {
            root: {
                color: '#fb2f19',
            },
        },
        MuiChip: {
            root: {
                borderRadius: '5px',
                opacity: 0.9,
            },
        },
    },
    palette: {
        primary: {
            main: '#2c54df',
        },
        secondary: {
            main: '#fb2f19',
        },
        text: {
            primary: '#ffffff',
            secondary: 'rgb(173,185,183)',
        },
        background: {
            default: 'rgb(15,20,38)',
            paper: 'rgb(20,27,45)',
        },
        common: {
            black: 'rgb(13,16,27)',
            white: '#ffffff',
        },
        custom: {
            blue: '#2c54df',
            gray: 'rgb(173,185,183)',
            orange: '#fb2f19',
        },
        success: {
            main: '#14DCAA',
        },
    },
});

export default theme;
